import React from "react";

//@ts-ignore
import IlluShieldEuro from "../../images/IlluSchildEuro.png";
import TextBlock from "../TextBlock/TextBlock";

const SectionRetter = () => {
  return (
    <section className="retter">
      <div className="grid grid-align-center">
        <div className="grid-8">
          <TextBlock
            topic="Die Vorteile der Haftpflicht"
            headline="Der Highscore-Retter im echten Leben"
            text="Eine Privathaftpflicht ist die vielleicht sinnvollste Versicherung, auch wenn sie nicht gesetzlich vorgeschrieben ist. Denn ein Versehen ist schnell passiert – und die Kosten für einen Schaden an Dritten oder deren Eigentum können schnell in die Millionenhöhe gehen.<br/><br/>Jetzt Privathaftpflicht in weniger als fünf Minuten abschließen und einen sorgenfreien Alltag genießen. "
            cta={{
              label: "Jetzt absichern",
              href: "https://www.huk24.de/haftpflichtversicherung",
            }}
          />
        </div>
        <div className="grid-4 offset-8 grid-right">
          <img style={{ width: "100%", height: "auto" }} src={IlluShieldEuro} />
        </div>
      </div>
    </section>
  );
};

export default SectionRetter;
