import React from "react";

import SpecialCard from "../SpecialCard/SpecialCard";
import TextBlock from "../TextBlock/TextBlock";
import IlluSparschwein from "../../images/IlluSparschwein";
import IlluHandy from "../../images/IlluHandy";
import IlluSchild from "../../images/IlluSchild";

const SectionSpecial = () => {
  return (
    <section className="special">
      <div className="grid">
        <div className="grid-12 grid-center">
          <TextBlock
            align="center"
            headline="Das macht die HUK24 so besonders"
            topic="Die wichtigsten Vorteile auf einen Blick"
            cta={{
              href: "https://www.huk24.de/haftpflichtversicherung",
              label: "Jetzt Tarif berechnen",
            }}
          >
            <div className="grid">
              <div className="grid-4">
                <SpecialCard
                  topic="Erstklassig"
                  text="Immer versichert in HUK-COBURG-Qualität"
                >
                  <IlluSchild />
                </SpecialCard>
              </div>
              <div className="grid-4 offset-4">
                <SpecialCard
                  topic="Preiswert"
                  text="Garantiert dauerhaft günstige Beiträge"
                >
                  <IlluSparschwein />
                </SpecialCard>
              </div>
              <div className="grid-4 offset-8">
                <SpecialCard
                  topic="Einfach"
                  text="Alle Angelegenheiten bequem online erledigen"
                >
                  <IlluHandy />
                </SpecialCard>
              </div>
            </div>
          </TextBlock>
        </div>
      </div>
    </section>
  );
};

export default SectionSpecial;
