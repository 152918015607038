import * as React from "react";
import { SVGProps } from "react";

const IlluHandy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={200}
    height={200}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 200"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m115.625 49.878 13.187-8.68L115.625 25l-19.077-3.125-12.173 9.375v5.824l6.25-2.699h12.5l12.5 15.503ZM112.726 178.505l18.524-9.755V75L90.625 49.878l-18.75 9.497v93.75l40.851 25.38Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m130.157 167.585-9.502 5.821 2.144-69.379s4.612-11.44 8.037-30.572c.847 19.712 1.93 66.186 1.488 89.051-.1 5.105-2.167 5.079-2.167 5.079Z"
      fill="#CBCFD2"
    />
    <path
      clipRule="evenodd"
      d="M121.064 170.14V83.247a7.373 7.373 0 0 0-3.68-6.378L83.207 56.755c-4.933-2.858-11.118.69-11.118 6.38l-.058 87.188a7.372 7.372 0 0 0 3.698 6.389l34.236 19.817c4.932 2.84 11.099-.71 11.099-6.389Z"
      stroke="#35444C"
      strokeWidth={1.002}
    />
    <path
      d="M128.027 170.347c2.057-1.267 3.507-3.519 3.507-6.274V77.18a7.374 7.374 0 0 0-3.683-6.379L93.849 50.887c-2.533-1.468-5.396-1.245-7.584.09M76.481 56.416l10.785-5.956"
      stroke="#35444C"
      strokeWidth={1.002}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M98.682 148.568c2.415 4.09 2.506 8.372-.018 9.854-2.525 1.483-6.75-.472-9.166-4.562-2.415-4.091-2.455-8.528.07-10.011 2.523-1.482 6.696.629 9.114 4.719Z"
      fill="#fff"
    />
    <path
      clipRule="evenodd"
      d="M98.682 148.568c2.415 4.09 2.506 8.372-.018 9.854-2.525 1.483-6.75-.472-9.166-4.562-2.415-4.091-2.455-8.528.07-10.011 2.523-1.482 6.696.629 9.114 4.719Z"
      stroke="#35444C"
      strokeWidth={0.891}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.102 29.525c9.775-5.741 25.607 1.865 34.554 17.013Z"
      fill="#fff"
    />
    <path
      d="M86.102 29.525c9.775-5.741 25.607 1.865 34.554 17.013"
      stroke="#35444C"
      strokeWidth={1.002}
    />
    <path
      clipRule="evenodd"
      d="m82.788 31.565 2.666 4.408c7.195-4.998 19.949-1.618 29.86 12.44l.291.455 13.207-7.67c-8.773-14.85-23.91-22.81-33.677-17.073l-12.347 7.44ZM112.726 136.597V87.463c0-2.023-.948-3.893-2.488-4.91L87.106 69.196c-3.336-2.204-7.522.528-7.522 4.91v49.146c0 2.03.956 3.907 2.506 4.922l23.132 13.343c3.335 2.185 7.504-.548 7.504-4.92Z"
      stroke="#35444C"
      strokeWidth={1.002}
    />
    <path
      d="m116.573 176.874 11.456-6.526"
      stroke="#35444C"
      strokeWidth={1.002}
    />
  </svg>
);

export default IlluHandy;
