import * as React from "react";
import { SVGProps } from "react";

const IconArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.536 4.414 6.95 3l4.95 4.95-4.95 4.95-1.414-1.415L9.07 7.95 5.536 4.414Z"
      fill="#fff"
    />
  </svg>
);

export default IconArrowRight;
