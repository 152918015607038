import * as React from "react";
import { SVGProps } from "react";

const IlluProtectionLaptop = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={88}
    height={91}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={15}
      y={24.5}
      width={58}
      height={38}
      rx={3}
      stroke="#000"
      strokeWidth={2}
    />
    <path stroke="#000" strokeWidth={2} strokeLinecap="round" d="M9 66.5h70" />
  </svg>
);

export default IlluProtectionLaptop;
