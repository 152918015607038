import * as React from "react";
import { SVGProps } from "react";

const IlluProtectionScreen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={88}
    height={90}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={9.101}
      y={21.75}
      width={66}
      height={38}
      rx={1}
      stroke="#000"
      strokeWidth={2}
    />
    <path
      d="M30.101 69.25h24M42.101 68.75v-9"
      stroke="#000"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      d="M59.79 33.75c-4.8 2-9.666 4.833-11.5 6 0 6.933-2.144 18 8 18 1.5 0 0-5 0-7 0-1.6 1.668-2.333 2.5-2.5 3.5-.833 10.8-2.8 12-4 1.2-1.2 2.167-.5 2.5 0l4 6.5c6-2.5.503-16.638-5-20-2.166.167-7.7 1-12.5 3Z"
      fill="#fff"
      stroke="#FB9105"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={57.291} cy={42.75} r={2} fill="#FB9105" />
    <circle cx={67.291} cy={39.75} r={2} fill="#FB9105" />
    <path
      d="m57.101 34.25 1.5-5.5-3.5-3.5M53.101 36.75l-5.5-2m0 0 3-4m-3 4-1.5-4h-5.5m7 12-7 2.5-4-2.5-7.5-2.5M47.601 50.75l-4.5 1-1.5 1.5m-2.5 4 2.5-4m0 0-4.5-5-5 3.5"
      stroke="#FB9105"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IlluProtectionScreen;
