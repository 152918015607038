import React from "react";

import useWindowSize, { Size } from "../../helpers/useWindowSize.hook";
import IconPlay from "../../images/IconPlay";
import "./SectionVideos.scss";

interface Video {
  id: string;
  attributes: {
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    thumbnailUrl: string;
    title: string;
    youtubeUrl: string;
  };
}

const SectionVideos = () => {
  const [videos, setVideos] = React.useState<Video[]>([]);
  const videoRef = React.useRef<HTMLDivElement>(null);
  const windowSize: Size = useWindowSize();

  React.useEffect(() => {
    fetch(`https://159.65.127.221/api/videos`)
      .then((response) => response.json())
      .then((result) => {
        const { data } = result;
        setVideos(data);
      })
      .catch((error) => {
        console.log("Videos could not be loaded");
      });
  }, []);

  React.useEffect(() => {
    const gridMaxWidth = parseInt(
      window
        .getComputedStyle(document.getElementsByTagName("body")[0])
        .getPropertyValue("--grid-max-width")
    );

    const windowWidth = Math.ceil((windowSize.width! - gridMaxWidth) / 2);

    if (videoRef.current && windowWidth > 0) {
      videoRef.current.style.setProperty("--offset", windowWidth.toString());
    }
  }, [windowSize]);

  React.useEffect(() => {
    setTimeout(() => {
      if (videoRef.current && videoRef.current.scrollLeft > 0) {
        videoRef.current.scrollLeft = 0;
      }
    }, 500);
  }, [videos]);

  return (
    <section className="videos">
      <div className="videos__background"></div>
      <div className="videos__scroller" ref={videoRef}>
        {videos &&
          videos.map((video) => (
            <div className="videos__video" key={video.id}>
              <IconPlay />
              <a
                href={video.attributes.youtubeUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={video.attributes.thumbnailUrl}
                  alt={video.attributes.title}
                />
              </a>
            </div>
          ))}
      </div>
    </section>
  );
};

export default SectionVideos;
