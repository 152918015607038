import React from "react";
import DamageReportCard from "../DamageReportCard/DamageReportCard";

import TextBlock from "../TextBlock/TextBlock";
import IlluProtectionScreen from "../../images/IlluProtectionScreen";
import IlluProtectionLaptop from "../../images/IlluProtectionLaptop";
import IlluProtectionSmartphone from "../../images/IlluProtectionSmartphone";

const SectionDamageReport = () => {
  return (
    <section className="damageReport">
      <div className="grid">
        <div className="grid-12 grid-center">
          <TextBlock
            topic="Online oder telefonisch"
            headline="So einfach geht die Schadensmeldung"
            align="center"
            cta={{
              label: "Hier abschließen",
              href: "https://www.huk24.de/haftpflichtversicherung",
            }}
          >
            <div className="grid">
              <div className="grid-4 grid-center">
                <DamageReportCard
                  text="Ein Schaden ist schnell mal passiert – zum Glück macht die HUK24 die Meldung so einfach wie möglich."
                  image={<IlluProtectionScreen />}
                />
              </div>
              <div className="grid-4 grid-center offset-4">
                <DamageReportCard
                  text="Jederzeit online im Kundenportal: Einfach einloggen und Schaden melden."
                  image={<IlluProtectionLaptop />}
                />
              </div>
              <div className="grid-4 grid-center offset-8">
                <DamageReportCard
                  text="Oder rund um die Uhr per Telefon: Unter 0800/2485445 gibt es zu jeder Zeit den vollen Schadenservice."
                  image={<IlluProtectionSmartphone />}
                />
              </div>
            </div>
          </TextBlock>
        </div>
      </div>
    </section>
  );
};

export default SectionDamageReport;
