import * as React from "react";
import Helmet from "react-helmet";

import "../styles/main.scss";

import Header from "../components/header/header";
import Footer from "../components/Footer/Footer";
import SectionGame from "../components/SectionGame/SectionGame";
import SectionHighscore from "../components/SectionHighscore/SectionHighscore";
import SectionRetter from "../components/SectionRetter/SectionRetter";
import SectionTim from "../components/SectionTim/SectionTim";
import SectionHaftpflicht from "../components/SectionHaftpflicht/SectionHaftpflicht";
import SectionSpecial from "../components/SectionSpecial/SectionSpecial";
import SectionProtection from "../components/SectionProtection/SectionProtection";
import SectionDamageReport from "../components/SectionDamageReport/SectionDamageReport";
import SectionMore from "../components/SectionMore/SectionMore";
import SectionVideos from "../components/SectionVideos/SectionVideos";
import SectionForm from "../components/SectionForm/SectionForm";

// markup
const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>Privathaftpflicht – spielend einfach verstehen | HUK24</title>
        <meta
          name="description"
          content="Das Privathaftpflicht-Spiel der HUK24 – so geht Versicherung spielend
          einfach. Jetzt mitspielen und jede Woche Gutscheine im Wert von 100€
          gewinnen."
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, viewport-fit=cover"
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Header />
      <main>
        <SectionGame />
        {/* <SectionForm /> */}
        {/* <SectionHighscore /> */}
        <SectionRetter />
        <SectionTim />
        <SectionHaftpflicht />
        <SectionSpecial />
        <SectionProtection />
        <SectionVideos />
        <SectionDamageReport />
        <SectionMore />
      </main>
      {/* <CookieBanner /> */}
      <Footer />
    </>
  );
};

export default IndexPage;
