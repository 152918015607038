import React from "react";

import "./Video.scss";

interface Video {
  videoSrcURL: string;
  videoTitle: string;
}

const Video: React.FC<Video> = (props) => {
  const { videoSrcURL, videoTitle } = props;

  return (
    <div className="video">
      <iframe
        src={videoSrcURL}
        title={videoTitle}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        allowFullScreen
      />
    </div>
  );
};

export default Video;
