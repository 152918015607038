import React from "react";

import "./DamageReportCard.scss";

interface DamageReportCard {
  text: string;
  image: React.ReactNode;
}

const DamageReportCard: React.FC<DamageReportCard> = (props) => {
  const { text, image } = props;

  return (
    <div className="damageReportCard">
      {image}
      <p className="damageReportCard__text">{text}</p>
    </div>
  );
};

export default DamageReportCard;
