import React from "react";

import IconArrowRight from "../../images/IconArrowRight";
import "./Button.scss";

interface Button {
  href: string;
  label: string;
}

const Button: React.FC<Button> = (props) => {
  const { href, label } = props;

  return (
    <div className="button">
      <a href={href} rel="noopener noreferrer">
        {label}
        <IconArrowRight />
      </a>
    </div>
  );
};

export default Button;
