import * as React from "react";
import { SVGProps } from "react";

const IlluSparschwein = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={200}
    height={200}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 200"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m105.924 139.087-1.684 10.599c0 2.083-4.312 3.77-9.632 3.77-5.32 0-9.633-1.687-9.633-3.77l-1.366-9.281"
      fill="#CBCFD2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M94.608 154.902c-5.457 0-10.98-1.75-11.109-5.096l-1.353-9.194 2.925-.413 1.383 9.487c0 .597 2.803 2.324 8.154 2.324 5.35 0 8.153-1.727 8.153-2.324l.018-.222 1.683-10.599 2.923.443-1.669 10.506c-.138 3.341-5.658 5.088-11.108 5.088Z"
      fill="#35444C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m135.838 121.543-1.977 14.007c0 2.081-4.313 3.77-9.632 3.77-5.322 0-9.634-1.689-9.634-3.77"
      fill="#CBCFD2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M124.228 140.765c-5.52 0-11.112-1.792-11.112-5.216h2.958c0 .597 2.803 2.324 8.154 2.324 5.352 0 8.154-1.727 8.154-2.324l.015-.198 1.976-14.005 2.929.396-1.964 13.921c-.124 3.351-5.649 5.102-11.11 5.102Z"
      fill="#35444C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66.385 133.27c0 2.082-4.312 3.771-9.632 3.771-5.32 0-9.634-1.689-9.634-3.771l-1.365-9.281"
      fill="#B4B4B4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.752 138.486c-5.456 0-10.98-1.75-11.109-5.096l-1.352-9.194 2.926-.413 1.381 9.487c0 .598 2.803 2.324 8.154 2.324 5.352 0 8.154-1.726 8.154-2.324h2.959c0 3.424-5.592 5.216-11.113 5.216Z"
      fill="#35444C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M144.8 68.347c12.219 22.656.609 52.131-25.931 65.834-17.705 9.14-37.585 8.953-52.483 1.088-7.436-3.925-13.63-9.76-17.698-17.301-12.218-22.657-.61-52.13 25.93-65.833 26.544-13.703 57.964-6.445 70.182 16.212Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.589 46.642c-9.233 0-18.611 2.297-27.28 6.771-25.777 13.309-37.133 41.964-25.314 63.879 3.79 7.025 9.7 12.801 17.093 16.703 14.934 7.883 34.51 7.464 51.091-1.094 12.52-6.464 22.042-16.692 26.811-28.8 4.742-12.038 4.21-24.496-1.498-35.08-5.707-10.582-15.917-18.043-28.751-21.008a53.973 53.973 0 0 0-12.152-1.37Zm-11.791 95.873c-8.895 0-17.56-1.985-25.116-5.974-7.909-4.175-14.24-10.364-18.3-17.899C34.8 95.316 46.71 64.908 73.93 50.855c13.152-6.79 27.887-8.8 41.491-5.656 13.677 3.158 24.574 11.14 30.686 22.473 6.114 11.333 6.695 24.64 1.643 37.47-5.027 12.759-15.039 23.527-28.191 30.318-9.095 4.696-19.063 7.055-28.761 7.055Z"
      fill="#35444C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M76.682 109.616c-1.1-.034-1.943-1.562-1.884-3.415.06-1.853.999-3.329 2.098-3.295 1.1.032 1.941 1.562 1.884 3.415-.06 1.855-.999 3.329-2.098 3.295ZM57.635 97.511c-1.1-.033-1.944-1.563-1.883-3.415.058-1.854.997-3.33 2.096-3.296 1.1.034 1.944 1.564 1.884 3.417-.059 1.853-.998 3.328-2.097 3.294Z"
      fill="#35444C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m65.718 136.494-1.528-2.477 4.782-2.822 1.528 2.477-4.782 2.822Z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m43.124 109.145-1.387-2.555 4.828-2.507 1.385 2.555-4.826 2.507ZM91.23 71.919l-3.523-1.958 23.819-13.474 3.644 1.998-23.94 13.434Z"
      fill="#35444C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.873 64.713c6.449-4.781 24.498-5.303 24.8-2.205.09.936-2.511 11.34-4.574 14.246"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m70.312 77.58-2.43-1.651c1.73-2.434 4-11.215 4.286-13.113-2.181-1.455-16.658-1.21-22.402 3.05L47.98 63.56c5.931-4.396 20.625-5.429 25.159-3.48 1.537.661 1.94 1.61 2.005 2.292.132 1.346-2.623 12.1-4.83 15.207ZM106.314 109.163l-1.855-2.254c5.766-4.54 9.112-14.604 9.22-19.824.041-1.901-.343-2.653-.493-2.782-2.513-2.156-17.388-.594-24.442 4.123l-1.669-2.387c6.957-4.66 23.756-7.599 28.058-3.913 1.048.896 1.552 2.585 1.503 5.017-.123 5.92-3.78 16.867-10.322 22.02Z"
      fill="#35444C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.137 75.142c-2.955-3.331-4.008-7.079-2.313-9.417 2.257-3.115 8.532-2.554 14.014 1.248 3.572 2.48 5.926 5.725 6.51 8.546-4.526-1.222-12.391-1.85-18.211-.377Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.004 72.841c2.815 0 5.614.26 8.026.687-1.039-1.903-2.826-3.833-5.048-5.375-2.441-1.693-5.104-2.745-7.494-2.958-1.214-.103-3.413-.076-4.458 1.366-1.129 1.557-.453 4.29 1.664 6.967 2.27-.48 4.796-.687 7.31-.687Zm12.268 4.698-2.317-.625c-4.437-1.197-12.059-1.736-17.447-.371l-.888.225-.6-.678c-3.517-3.965-4.461-8.364-2.403-11.202 1.41-1.945 3.94-2.858 7.139-2.573 2.902.259 6.076 1.494 8.936 3.48 3.768 2.614 6.424 6.143 7.104 9.438l.476 2.306ZM88.952 88.343c7.61 5.007 11.717 9.85 16.462 17.259 1.967-3.536 1.377-9.78-2.781-14.111-4.042-4.21-9.969-4.97-13.681-3.148Zm16.011 22.071-.946-1.522c-5.5-8.835-9.475-13.716-18.495-19.281l-1.615-.998 1.392-1.28c4.37-4.017 13.448-4.112 19.491 2.178 5.621 5.857 6.338 15.237 1.502 19.683l-1.329 1.22ZM154.874 77.948c-3.226 0-6.577-1.424-9.09-4.093l2.174-1.961c2.99 3.179 7.445 4.087 10.59 2.165 3.527-2.157 4.243-6.914 1.921-12.724l2.753-1.056c3.395 8.49.622 13.952-3.107 16.232-1.583.97-3.393 1.437-5.241 1.437Z"
      fill="#35444C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M146.014 69.615c-4.034-7.481-8.804-12.718-16.162-16.64a40.82 40.82 0 0 1 6.006 8.368c12.219 22.656.609 52.131-25.932 65.833-17.775 9.177-37.731 8.939-52.651.982 14.685 15.864 40.34 17.524 63.269 5.686 26.54-13.702 37.688-41.572 25.47-64.23Z"
      fill="#CBCFD2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.05 105.954c-1.687 0-3.093.47-4.097 1.392-3.525 3.24-1.347 11.345 4.754 17.7 3.101 3.228 6.738 5.572 10.24 6.597 3.223.942 5.895.638 7.518-.853 1.625-1.493 2.1-4.081 1.339-7.289-.828-3.484-2.991-7.18-6.094-10.411-3.1-3.229-6.737-5.572-10.239-6.597-1.23-.36-2.38-.539-3.421-.539Zm14.328 29.12c-1.334 0-2.77-.218-4.279-.66-3.993-1.169-8.096-3.791-11.547-7.388-7.446-7.755-9.478-17.325-4.624-21.788 2.424-2.229 6.112-2.768 10.39-1.517 3.994 1.17 8.096 3.792 11.549 7.389 3.45 3.595 5.874 7.764 6.816 11.737 1.012 4.253.232 7.822-2.194 10.051-1.569 1.442-3.666 2.176-6.11 2.176Z"
      fill="#35444C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.673 134.33c-4.187 3.849-13.072 1.247-19.848-5.809-6.776-7.055-8.873-15.894-4.688-19.742 4.187-3.849 13.072-1.248 19.848 5.807 6.776 7.055 8.875 15.895 4.688 19.744Z"
      fill="#fff"
    />
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={37}
      y={105}
      width={33}
      height={33}
    >
      <path d="M37.5 105.54h31.81v32.029H37.5V105.54Z" fill="#fff" />
    </mask>
    <g mask="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.242 108.443c-1.646 0-3.063.445-4.092 1.39-1.624 1.493-2.1 4.081-1.34 7.287.828 3.484 2.991 7.182 6.093 10.411 6.097 6.353 14.228 8.982 17.758 5.745 1.625-1.494 2.1-4.082 1.339-7.288-.828-3.484-2.99-7.182-6.093-10.411-4.321-4.502-9.663-7.134-13.665-7.134Zm14.294 29.126c-4.852 0-10.81-2.872-15.789-8.056-3.452-3.596-5.872-7.765-6.816-11.737-1.01-4.253-.23-7.824 2.194-10.051 4.857-4.464 14.493-1.884 21.939 5.872 3.451 3.594 5.872 7.763 6.815 11.737 1.01 4.252.232 7.82-2.194 10.049-1.608 1.479-3.742 2.186-6.149 2.186Z"
        fill="#35444C"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.168 127.9c-1.1-.033-1.945-1.562-1.884-3.415.059-1.853.998-3.328 2.097-3.294 1.1.032 1.942 1.562 1.883 3.415-.058 1.853-.997 3.328-2.096 3.294ZM48.29 122.814c-1.1-.034-1.944-1.564-1.883-3.417.059-1.853.997-3.328 2.097-3.295 1.1.034 1.942 1.564 1.883 3.417-.06 1.853-.997 3.329-2.098 3.295ZM90.803 142.524c-9.042 0-17.845-2.057-25.5-6.186l1.428-2.533c14.992 8.086 34.707 7.74 51.448-.903 12.52-6.464 22.043-16.693 26.811-28.8 4.743-12.039 4.21-24.498-1.498-35.079-5.706-10.583-15.917-18.043-28.751-21.01-12.909-2.981-26.91-1.063-39.433 5.4l-1.378-2.559c13.151-6.788 27.887-8.799 41.49-5.655 13.676 3.16 24.575 11.14 30.687 22.474 6.114 11.332 6.696 24.64 1.643 37.469-5.028 12.761-15.038 23.527-28.191 30.317-9.11 4.704-19.071 7.065-28.756 7.065Z"
      fill="#35444C"
    />
  </svg>
);

export default IlluSparschwein;
