import * as React from "react";
import { SVGProps } from "react";

const IconPlay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={110}
    height={110}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 110 110"
    {...props}
  >
    <circle opacity={0.8} cx={55} cy={55} r={55} fill="#fff" />
    <path
      d="M42 77.27V31.78a1 1 0 0 1 1.52-.855L77.713 51.72a1 1 0 0 1 .066 1.665L43.586 78.081A1 1 0 0 1 42 77.27Z"
      fill="#131313"
    />
  </svg>
);

export default IconPlay;
