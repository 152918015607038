import * as React from "react";
import { SVGProps } from "react";

const IlluProtection = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    width={192}
    height={248}
    viewBox="0 0 192 248"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".st0,.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#fff}.st1{fill:#cbcfd2}.st2,.st3{fill:#35444c}.st3{fill-rule:evenodd;clip-rule:evenodd}"
      }
    </style>
    <path
      className="st0"
      d="M3.1 28.9c0 1.8.6 15.9 0 62.8 0 32.5 5 41 9.4 52.3 29.6 76.5 90.3 98.6 90.3 98.6s65.8-16.5 65.8-64.9v-64.4c0-20.7-11.6-34.4-32.9-39.4-13.8-3.2-36.2-11.2-57.5-23.3C51.6 35.4 35.8 18.3 28.7 13c-6-4.5-25.6.3-25.6 15.9"
    />
    <path
      className="st1"
      d="M104.5 241.2c-5.9-1.3-47-19.8-30.8-18.5 94.8 7.8 84.2-118.7 69.8-143.9 23.3 7.2-4-4.7 3.1 1s20.3 12 20.3 38.1 1.4 60.5 0 64.9c-1.4 4.3-2.2 28.4-25 41.2-22.7 12.8-37.4 17.2-37.4 17.2z"
    />
    <path
      className="st1"
      d="m184.9 190.3-27.1 25.5 3.3-127.7s19.3 3.9 17.3-12.6c9.3 5 6.4 7.2 6.4 7.2v107.6h.1z"
    />
    <path
      className="st2"
      d="M156.4 57.9c-26.2-5.6-43.7-11.8-60.6-21.5-9.9-5.7-18.7-13.4-26.4-20.3-6-5.3-11.1-9.8-15.8-12.5C42.2-3 21.4-1 7.3 13.8 2.6 17.6 0 22.9 0 28.9v1.5c.3 13.8.3 34.4 0 61.3 0 29.5 4.2 39.8 8.2 49.8.5 1.2 1 2.4 1.4 3.6 25.1 64.9 71.6 91.1 87 98.2 2.8 1.3 4.6 2 5 2.1l.8.3h.1l.9-.2c.7-.2 7.6-2 16.7-5.9 2-.6 18.4-5.9 35-16.7 23.4-15.3 35.8-34.4 35.8-55.2v-64.4c.2-23.5-13.1-40.9-34.5-45.4zM6.1 91.8c.3-26.9.4-47.6 0-61.5v-1.4c0-7.4 5.3-11.1 8.4-12.6 2.8-1.4 5.7-2 8.1-2 1.9 0 3.4.4 4.3 1 1.7 1.3 4 3.4 7 6 8.5 7.6 22.7 20.3 42.7 31.7 21.9 12.5 44.5 20.4 58.4 23.7 20 4.7 30.5 17.3 30.5 36.5v64.4c0 12.4-4.5 23.7-13.5 33.5-4.5 4.9-10.1 9.6-16.8 13.8-.3.2-.6.4-1 .6-.8.5-1.7 1-2.5 1.5-12.8 7.5-25.2 11.3-28.7 12.2-3.5-1.4-16.7-7.3-32.5-19.9-1-.8-2.1-1.7-3.1-2.6-10.9-9.2-23.9-22.7-35.6-41.3-6-9.5-11.6-20.4-16.3-32.7-.5-1.2-1-2.5-1.5-3.7-4.1-9.6-7.9-18.9-7.9-47.2zM185 167.7c0 20.2-12.9 35.4-27.4 46.2.6-.7 1.1-1.3 1.6-2 8.1-10.2 12.3-21.7 12.3-34.2v-64.4c0-22-12.5-37-35.2-42.4-12.8-3-35.4-10.9-56.7-23-19.4-11.1-32.8-23-41.6-31-3-2.7-5.4-4.9-7.4-6.3-1.4-1-3.1-1.7-5.1-2 9.3-3.7 18.9-3.4 25 .2 4.1 2.4 9.1 6.8 14.8 11.8 8 7 17 15 27.4 21 17.5 10.1 35.6 16.5 62.4 22.2 18.7 4 29.9 18.7 29.9 39.5v64.4zM102.6 245.8h-.1z"
    />
    <path
      className="st0"
      d="m126.5 176.8 19.6-12.8-72.4-47 12.8 86.3 17.5-11.1 31.2 50.3 22.6-17.1-31.3-48.6z"
    />
    <path
      className="st3"
      d="m105 188.1 31.1 50.1 17.7-13.4-31.5-48.8 18.3-11.9-62.8-40.8 11.1 75 16.1-10.2zm29.5 58.9-31.4-50.6-18.9 11.9-14.5-97.6 81.9 53.3-21 13.7 31.4 48.5-27.5 20.8z"
    />
    <path
      className="st0"
      d="m124.2 177.8 19.6-12.8-72.4-47 12.8 86.3 17.5-11.1 31.2 50.3 22.6-17.1-31.3-48.6z"
    />
    <path
      className="st3"
      d="m102.8 189 31.1 50.1 17.7-13.4-31.6-48.8 18.3-11.9-62.8-40.8 11.1 75 16.2-10.2zm29.4 59-31.4-50.6-18.9 11.9-14.5-97.6 81.9 53.3-21 13.7 31.4 48.5-27.5 20.8zM90.7 110.7l-3-5.2 11.8-8 3.2 5.2-12 8zM77.5 95.1l-6-2.9.1-19.2 6.1 2.8-.2 19.3zM48.1 138l-2.7-4.8 11.8-8 2.9 4.7-12 8.1zm-11.2-34.4v-6.5l16.5 7.1-.1 6.5-16.4-7.1zm8.5-27.2 5.1-3.5L62.4 92l-5.2 3.5-11.8-19.1z"
    />
    <path className="st3" d="m67.4 111.8 2.3-1 2.6 4.2-4.9-3.2z" />
    <path
      d="m132.2 248 3-4-.7 3-2.3 1zm-50.3-38.7 3-4-.7 3-2.3 1z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
    />
    <path
      className="st3"
      d="m156 222.4-3.4-5c21.5-14.2 32.3-30.9 32.3-49.7v-64.4c0-20.7-11.2-35.5-29.9-39.5l1.3-5.9c21.4 4.6 34.7 21.9 34.7 45.4v64.4c.1 20.6-12 39.5-35 54.7z"
    />
  </svg>
);

export default IlluProtection;
