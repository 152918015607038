import React from "react";

import IlluProtection from "../../images/IlluProtection";
import TextBlock from "../TextBlock/TextBlock";

const SectionProtection = () => {
  return (
    <section className="protection -background-yellow-light">
      <div className="grid grid-align-center">
        <div className="grid-4 grid-right">
          <IlluProtection />
        </div>
        <div className="grid-8 offset-5">
          <TextBlock
            topic="Tim erklärt"
            headline="Perfekter Schutz: schnell und digital"
            text="Versicherungscoach Tim ist nicht nur im Game ein starker Typ: In diesen Videos erklärt er alles Wissenswerte rund um die Haftpflichtversicherung der HUK24."
            cta={{
              label: "Jetzt absichern",
              href: "https://www.huk24.de/haftpflichtversicherung",
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default SectionProtection;
