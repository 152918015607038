import React from "react";

import MoreCard from "../MoreCard/MoreCard";
import TextBlock from "../TextBlock/TextBlock";

//@ts-ignore
import IlluCouch from "../../images/IlluMoreCouch.png";
//@ts-ignore
import IlluCar from "../../images/IlluMoreCar.png";

const SectionMore = () => {
  return (
    <section className="more">
      <div className="grid">
        <div className="grid-12 grid-center">
          <TextBlock headline="Weitere spannende Produkte" align="center" />
          <div className="grid grid-stretch">
            <div className="grid-6">
              <MoreCard
                title="Sicher & günstig: Unsere Kfz-Versicherung"
                href="https://www.huk24.de/autoversicherung?ID=C7099"
                label="Jetzt Vorteile entdecken"
              >
                <img src={IlluCar} alt="Gelbes Auto" />
              </MoreCard>
            </div>
            <div className="grid-6 offset-6">
              <MoreCard
                title="Immer abgesichert mit unserer Hausrat"
                href="https://www.huk24.de/hausratversicherung?ID=C7099"
                label="Jetzt Vorteile entdecken"
              >
                <img src={IlluCouch} alt="Gelbes Sofa" />
              </MoreCard>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionMore;
