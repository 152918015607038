import React from "react";

import TextBlock from "../TextBlock/TextBlock";
import Video from "../Video/Video";

const SectionTim = () => {
  return (
    <section className="tim -background-yellow-light">
      <div className="grid">
        <div className="grid-12 grid-center">
          <TextBlock
            topic="Gibt’s auch in echt: Versicherungscoach Tim"
            headline="Tim erklärt, was jeder über die  Privathaftpflicht wissen sollte"
            cta={{
              label: "Jetzt absichern",
              href: "https://www.huk24.de/haftpflichtversicherung",
            }}
            align="center"
          >
            <Video
              videoSrcURL="https://www.youtube-nocookie.com/embed/D38DlqwLSTs"
              videoTitle="Haftpflichtversicherung: Deswegen brauchst du sie unbedingt!"
            />
          </TextBlock>
        </div>
      </div>
    </section>
  );
};

export default SectionTim;
