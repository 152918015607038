import * as React from "react";
import { SVGProps } from "react";

const IlluProtectionSmartphone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={90}
    height={90}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={37}
      y={30}
      width={16}
      height={30}
      rx={3}
      stroke="#000"
      strokeWidth={2}
    />
    <circle cx={45} cy={55} r={2} fill="#000" />
  </svg>
);

export default IlluProtectionSmartphone;
