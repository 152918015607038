import React from "react";

import DamageCard from "../DamageCard/DamageCard";
import TextBlock from "../TextBlock/TextBlock";
import "./SectionHaftpflicht.scss";

//@ts-ignore
import ItemAuto from "../../images/items/Item_Auto.png";
//@ts-ignore
import ItemBagger from "../../images/items/Item_Bagger.png";
//@ts-ignore
import ItemBlumentopf from "../../images/items/Item_Blumentopf.png";
//@ts-ignore
import ItemController from "../../images/items/Item_Controller.png";
//@ts-ignore
import ItemDrone from "../../images/items/Item_Drone.png";
//@ts-ignore
import ItemEinkaufstüte from "../../images/items/Item_Einkaufstüte.png";
//@ts-ignore
import ItemEmailvirus from "../../images/items/Item_Emailvirus.png";
//@ts-ignore
import ItemFahrrad from "../../images/items/Item_Fahrrad.png";
//@ts-ignore
import ItemFernseher from "../../images/items/Item_Fernseher.png";
//@ts-ignore
import ItemFisch from "../../images/items/Item_Fisch.png";
//@ts-ignore
import ItemGeldbündel from "../../images/items/Item_Geldbündel.png";
//@ts-ignore
import ItemLeiter from "../../images/items/Item_Leiter.png";
//@ts-ignore
import ItemSalatschüssel from "../../images/items/Item_Salatschüssel.png";
//@ts-ignore
import ItemSchlüssel from "../../images/items/Item_Schlüssel.png";
//@ts-ignore
import ItemSmartphone from "../../images/items/Item_Smartphone.png";
//@ts-ignore
import ItemWagenheber from "../../images/items/Item_Wagenheber.png";
//@ts-ignore
import ItemFoehn from "../../images/items/Item_Foehn.png";
//@ts-ignore
import ItemFussball from "../../images/items/Item_Fussball.png";
//@ts-ignore
import ItemKaffeetasse from "../../images/items/Item_Kaffeetasse.png";

const damages = [
  {
    title: "E-Bike",
    text: "Schäden, die beim Fahren mit dem Fahrrad verursacht werden",
    image: ItemFahrrad,
  },
  {
    title: "Auto",
    text: "Unfallschäden durch Unachtsamkeit als Spaziergänger",
    image: ItemAuto,
  },
  {
    title: "Bagger",
    text: "Schäden an kleineren, geliehenen Gartengeräten",
    image: ItemBagger,
  },
  {
    title: "Undichter Blumentopf",
    text: "Allmählichkeitsschäden an fremdem Eigentum",
    image: ItemBlumentopf,
  },
  {
    title: "Controller",
    text: "Sachschäden an den Gegenständen Dritter",
    image: ItemController,
  },
  {
    title: "Drone",
    text: "Schäden durch Drohnen bis 250 Gramm Startmasse",
    image: ItemDrone,
  },
  {
    title: "Fernseher",
    text: "Gefälligkeitsschäden bei Hilfe für Bekannte – z.B. Umzug",
    image: ItemFernseher,
  },
  {
    title: "Koikarpfen",
    text: "Schäden durch zahme Haustiere wie Katzen",
    image: ItemFisch,
  },
  {
    title: "Geldscheine",
    text: "Forderungsausfalldeckung, falls Dritte einen Schaden nicht zahlen",
    image: ItemGeldbündel,
  },
  {
    title: "Leiter",
    text: "Gefälligkeitsschäden bei Hilfe für Bekannte – z.B. Renovierung",
    image: ItemLeiter,
  },
  {
    title: "Einkaufstüte",
    text: "Personenschäden bei Dritten durch fahrlässiges Handeln",
    image: ItemEinkaufstüte,
  },
  {
    title: "Salatschüssel",
    text: "Schäden an der Mietwohnung",
    image: ItemSalatschüssel,
  },
  {
    title: "Schlüssel",
    text: "Schäden durch den Verlust von Schlüsseln oder Codekarten",
    image: ItemSchlüssel,
  },
  {
    title: "Smartphone",
    text: "Schäden an Gegenständen Dritter",
    image: ItemSmartphone,
  },
  {
    title: "E-Mail mit Virus",
    text: "Schäden durch das Weiterleiten von Viren",
    image: ItemEmailvirus,
  },
  {
    title: "Wagenheber",
    text: "Gefälligkeitsschäden bei Hilfe für Bekannte – z.B. Reifenwechsel",
    image: ItemWagenheber,
  },
  {
    title: "Kaffeetasse",
    text: "Schäden an der Mietwohnung",
    image: ItemKaffeetasse,
  },
  {
    title: "Fussball",
    text: "Schäden durch deliktunfähige Kinder",
    image: ItemFussball,
  },
  {
    title: "Föhn",
    text: "Schäden an der Einrichtung gemieteter Hotelzimmern",
    image: ItemFoehn,
  },
];

const SectionHaftpflicht = () => {
  const [maxItems, setMaxItems] = React.useState(9);

  const clickHandler = () => {
    setMaxItems(damages.length);
  };

  return (
    <section className="haftpflicht">
      <div className="grid">
        <div className="grid-12">
          <TextBlock
            topic="Perfekter schutz auf jedem Level"
            headline="Bei diesen Schäden hilft die Haftpflicht"
            align="center"
          />
          <div className="haftpflicht__damages">
            {damages.map((damage, index) => {
              if (index < maxItems) {
                return (
                  <DamageCard key={damage.title} {...damage}>
                    <img src={damage.image} alt="" />
                  </DamageCard>
                );
              }
            })}
          </div>
          {maxItems === 9 && (
            <div className="haftpflicht__button">
              <button onClick={clickHandler}>Weitere Schäden anzeigen</button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default SectionHaftpflicht;
