import React from "react";
import IconArrowRight from "../../images/IconArrowRight";
import Button from "../Button/Button";

import "./TextBlock.scss";

interface TextBlock {
  topic?: string;
  topicColor?: string;
  headline: string;
  headlineColor?: string;
  text?: string;
  align?: "left" | "center" | "right";
  cta?: {
    label: string;
    href: string;
  };
  children?: React.ReactNode;
}

const TextBlock: React.FC<TextBlock> = (props) => {
  const {
    topic,
    headline,
    text,
    align = "left",
    cta,
    headlineColor = "var(--color-gray-dark)",
    topicColor = "var(--color-gray-dark)",
    children,
  } = props;

  return (
    <div className={`textBlock --${align}`}>
      {topic && (
        <p className="topic" style={{ color: topicColor }}>
          {topic}
        </p>
      )}
      <h2 style={{ color: headlineColor }}>{headline}</h2>
      {text && <p dangerouslySetInnerHTML={{ __html: text }}></p>}
      {children}
      {cta && <Button href={cta.href} label={cta.label} />}
    </div>
  );
};

export default TextBlock;
