import * as React from "react";
import { SVGProps } from "react";

const IlluSchild = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={200}
    height={200}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 200"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M103.071 155.565s33.327-10.451 33.327-34.904V88.123c0-10.462-5.288-19.134-15.644-21.431-11.898-2.64-20.814-5.662-29.765-11.036-8.608-5.165-15.504-13.562-20.44-16.556-4.85-2.942-14.194-2.128-20.631 4.975"
      fill="#fff"
    />
    <path
      d="M103.071 155.565s33.327-10.451 33.327-34.904V88.123c0-10.462-5.288-19.134-15.644-21.431-11.898-2.64-20.814-5.662-29.765-11.036-8.608-5.165-15.504-13.562-20.44-16.556-4.85-2.942-14.194-2.128-20.631 4.975"
      stroke="#35444C"
      strokeWidth={0.975}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.875 50.596c0 .89.287 8.054 0 31.708 0 16.4 2.436 20.689 4.56 26.417 14.313 38.629 43.679 49.775 43.679 49.775s31.83-8.32 31.83-32.773V93.184c0-10.46-5.612-17.383-15.917-19.914-6.662-1.637-17.543-5.66-27.85-11.782-12.825-7.62-20.445-16.252-23.87-18.943-2.912-2.285-12.432.15-12.432 8.051Z"
      fill="#fff"
    />
    <path
      clipRule="evenodd"
      d="M46.875 50.596c0 .89.287 8.054 0 31.708 0 16.4 2.436 20.689 4.56 26.417 14.313 38.629 43.679 49.775 43.679 49.775s31.83-8.32 31.83-32.773V93.184c0-10.46-5.612-17.383-15.917-19.914-6.662-1.637-17.543-5.66-27.85-11.782-12.825-7.62-20.445-16.252-23.87-18.943-2.912-2.285-12.432.15-12.432 8.051Z"
      stroke="#35444C"
      strokeWidth={0.975}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.965 157.812c-2.837-.652-22.735-9.998-14.889-9.326 45.867 3.929 40.764-59.93 33.805-72.683 11.259 3.637-1.929-2.356 1.515.512 3.446 2.87 9.843 6.078 9.843 19.243 0 13.166.658 30.551 0 32.747-.655 2.194-1.053 14.341-12.111 20.802-11.058 6.458-18.163 8.705-18.163 8.705Z"
      fill="#CBCFD2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m134.857 132.062-13.115 12.876 1.603-64.467s9.331 1.949 8.394-6.384c4.497 2.522 3.118 3.657 3.118 3.657v54.318Z"
      fill="#CBCFD2"
    />
    <path
      d="M51.435 108.721c14.314 38.629 43.678 49.776 43.678 49.776s31.83-8.321 31.83-32.776V93.184c0-10.46-5.612-17.383-15.915-19.914-6.664-1.636-17.545-5.66-27.85-11.782-12.827-7.62-20.445-16.251-23.871-18.942"
      stroke="#35444C"
      strokeWidth={0.975}
    />
    <path
      d="M120.094 147.037c8.21-5.674 16.304-14.323 16.304-26.375v-32.54c0-10.46-5.288-19.133-15.644-21.43"
      stroke="#35444C"
      strokeWidth={0.975}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m113.457 152.908 12.633 7.5 10.196-5.72v-3.863l2.956 1.792 10.114-5.692v-15.261l-13.07-8.216-.064-15.327-13.133-8.009-9.632 5.723v3.899l-3.502-1.82-9.357 5.852-.264 15.468 12.904 7.939.219 15.735Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m126.372 160.25 9.915-5.563v-3.863l-9.915-5.471v14.897ZM139.649 152.068l9.914-5.561v-15.084l-9.914 5.75v14.895ZM126.372 129.196l9.915-5.991V108.12l-9.915 5.75v15.326Z"
      fill="#CBCFD2"
    />
    <path
      d="m136.287 123.448-9.501 5.645M149.357 131.423l-9.501 5.645M135.873 108.121l-9.501 5.645M136.287 150.824v3.863l-9.893 5.88-.025.009-13.13-7.799v-15.605l-13.134-7.802v-15.603l9.85-5.852 3.284 1.95M113.239 105.963l13.133 7.803-.001 15.603 13.135 7.804v15.601"
      stroke="#35444C"
      strokeWidth={0.975}
    />
    <path
      d="m100.105 113.766 13.134 7.802v-15.604l9.85-5.852 13.134 7.803-.002 15.604 13.136 7.803v15.603l-9.851 5.849-13.135-7.8-.002 15.603"
      stroke="#35444C"
      strokeWidth={0.975}
    />
  </svg>
);

export default IlluSchild;
