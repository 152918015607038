import React from "react";

import Button from "../Button/Button";
import "./MoreCard.scss";

interface MoreCard {
  title: string;
  label: string;
  href: string;
  children: React.ReactNode;
}

const MoreCard: React.FC<MoreCard> = (props) => {
  const { title, label, href, children } = props;

  return (
    <div className="moreCard">
      {children}
      <p className="moreCard__title">{title}</p>
      <Button href={href} label={label} />
    </div>
  );
};

export default MoreCard;
