import React from "react";

import TextBlock from "../TextBlock/TextBlock";
import "./SectionGame.scss";

const SectionGame = () => {
  React.useEffect(() => {
    // @ts-ignore
    import("../../../../huk-sidescroller/src/styles.css");
    import("../../../../huk-sidescroller/src/index");
  }, []);

  return (
    <section className="game -background-gray-dark">
      <div className="grid">
        <div className="grid-12">
          <TextBlock
            headline="Jetzt spielen & 10x200€ Amazon-Gutschein gewinnen!"
            headlineColor="#FFF"
            topic="Das HUK24 Privathaftpflicht Game"
            topicColor="var(--color-orange)"
            align="center"
          />
          <div id="hukgame">
            <div className="loader">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionGame;
