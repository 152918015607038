import React from "react";

import "./SpecialCard.scss";

interface SpecialCard {
  topic: string;
  text: string;
}

const SpecialCard: React.FC<SpecialCard> = (props) => {
  const { topic, text, children } = props;
  return (
    <div className="specialCard">
      <p className="specialCard__topic">{topic}</p>
      <p className="specialCard__text">{text}</p>
      <div className="specialCard__image">{children}</div>
    </div>
  );
};

export default SpecialCard;
