import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import "./DamageCard.scss";

interface DamageCard {
  title: string;
  text: string;
}

const DamageCard: React.FC<DamageCard> = (props) => {
  const { title, text, children } = props;

  return (
    <div className="damageCard">
      {children}
      <p className="damageCard__title">{title}</p>
      <p className="damageCard__text">{text}</p>
    </div>
  );
};

export default DamageCard;
